@keyframes backdropFadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      display: none;
    }
  }

  @keyframes scaleDown {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(20%);
    }
  }

  @keyframes slideInUp {
    0% {
      opacity: 0;
      transform: translateY(20%);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .backdrop {
    backdrop-filter: blur(5px);
  }

  .backdrop-off {
    animation: backdropFadeOut 0.1s ease-out forwards;
  }

  @media (prefers-reduced-motion: no-preference) {
    .dialog {
      opacity: 0;
      transform: scale(0.9);
    }

    .showing {
      animation: slideInUp 0.2s ease-out forwards;
    }

    .closing {
      animation: scaleDown 0.2s ease-in forwards;
    }
  }

  .close-button {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
  }