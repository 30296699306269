body{
  background-color: rgba(0,0,0,0.01);
}
.mobile-container {
  background-color: white;
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0,0,0,0.05);
  -moz-box-shadow: 0px 0px 100px 0px rgba(0,0,0,0.05);
  box-shadow: 0px 0px 100px 0px rgba(0,0,0,0.05);
}

.rating-card {
  background: linear-gradient(135deg, #4c4c4c 0%,#595959 12%,#666666 25%,#474747 39%,#2c2c2c 50%,#000000 51%,#111111 60%,#2b2b2b 76%,#1c1c1c 91%,#131313 100%);
}